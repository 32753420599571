<template>
  <div class="page">
    <div class="c-page o-page-wrapper c-page_meet">
      <Sidebar :status="'peopleatsparrow'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content">
          <Content></Content>
        </div>
        <TeamList></TeamList>
        <div class="c-page__content c-page__content_mobile">
          <LikeSuggestion></LikeSuggestion>
          <GetStartedContent></GetStartedContent>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import Content from '@/components/peopleatsparrow/MeetTeamContent.vue'
import TeamList from '@/components/peopleatsparrow/Team.vue'
import GetStartedContent from '@/components/GetStartedContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'

export default {
  name: 'Team',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    Content,
    TeamList,
    GetStartedContent,
    LikeSuggestion
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner/Meet Sparrow Team-3.png'),
          // title:"Join Sparrow and <br />make an impact <span class='c-page-banner__regular'>in a <br />fast-growing and <br />innovative industry</span>",
          title:"Join Sparrow and make an impact <span class='c-page-banner__regular'>in a fast-growing and innovative industry</span>",
          buttonText:"Find out more >",
          buttonPage:"/people-at-sparrow/join-sparrow"
        },
      ],
      theme: 'one',
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Meet the Team of Experts at Sparrow')
    this.updateDescriptionMeta('Sparrow is equipped with professional traders and experts specializing in finance, legal, compliance, information technology, digital assets, and operational excellence.')
    this.updateKeywordMeta('Sparrow, wealth management, derivatives')
  }
}
</script>
