<template>
  <div class="c-about-modal">
    <div class="c-about-modal__mask">
      <div class="c-about-modal__wrapper">
        <div class="c-about-modal__container" v-click-outside="close">

          <div class="c-about-modal__header">
            <img class="c-about-modal__close-modal" src="@/assets/images/close.svg" alt="close-modal" @click="close">
            <slot name="name"></slot>
            <slot name="role"></slot>
          </div>

          <div class="c-about-modal__divider"></div>

          <div class="c-about-modal__body">
            <slot name="content"></slot>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import vClickOutside from 'v-click-outside'
  export default {
    name: 'ModalTeam',
    directives: {
      clickOutside: vClickOutside.directive
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }

</script>
